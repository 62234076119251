import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../components/LayoutDAI'
import Section from '../../components/dai/Section'
import IconCard from '../../components/dai/IconCard'
import { Heading1 } from '../../components/dai/Heading1'
import { Heading2 } from '../../components/dai/Heading2'
import { Text } from '../../components/dai/Text'
import Columns from '../../components/dai/Columns'
import Column from '../../components/dai/Column'
import PageNavigation from '../../components/dai/PageNavigation'
import { BoldText } from '../../components/dai/BoldText'
import LabelText from '../../components/dai/LabelText'
import { Button } from '../../components/dai/Button'

import SEO from '../../components/SEO'
import Space from '../../components/common/Space'
import TextLink from '../../components/common/TextLink'

import InspectCode from '../../images/dai/icons/assets/inspect-code.svg'
import PhotoVideo from '../../images/dai/icons/assets/photo-video.svg'
import Checklist from '../../images/dai/icons/assets/checklist.svg'
import PersonComputer from '../../images/dai/icons/assets/person-computer.svg'
import MultiSites from '../../images/dai/icons/assets/multi-sites.svg'
import LockError from '../../images/dai/icons/assets/lock-error.svg'

const MaxImage = styled.div`
  max-width: 200px;
`

export default function Methodology() {
  return (
    <LayoutDAI pagePath={{ parent: 'methodology', child: '' }}>
      <SEO
        title="Methodology | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/methodology/"
        desc="Learn about AudioEye's methodology, which utilized a mix of automated scans and human experts, for conducting the 2023 Digital Accessibility Index Report."
        bannerUrl="https://images.prismic.io/audioeye-web/6af16e89-39ef-4d1a-b420-d1e357b30c76_methodology.jpg?auto=compress,format"
        imageAlt="Multiple signs that contain symbols about accessibility related issues like vision, broken links, contrast, etc."
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/methodology/`}
      />
      <Section paddingTop="md" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          METHODOLOGY
        </Text>
        <Space height={24} />
        <Heading1 heading1 alt aria-label="Overall Results">
          How we calculated our Index Average.
        </Heading1>
        <Space height={32} />
        <Text>
          In the real world, it takes both people and tools to keep our roads in good condition. It may be heavy
          equipment doing most of the digging, but it’s people planning where to dig next.
        </Text>
        <Space height={32} />
        <Text>
          We think about digital accessibility the same way. There’s a clear need for <em>tools</em> like AI-driven
          automation to help us find and fix the majority of accessibility barriers. But there’s an equal need for
          people to identify certain issues and come up with solutions that deliver the best possible user experience.
        </Text>
        <Space height={32} />
        <Text>
          Our <BoldText>Digital Accessibility Index</BoldText> reflects this perspective. We started with an automated
          scan, then had human experts audit the top sites in each industry, focusing on the most important page
          elements and user actions.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          AUTOMATED SCAN
        </Text>
        <Space height={32} />
        <Columns unorderedList>
          <Column size={8} listItem>
            <IconCard noCard>
              <InspectCode />
              <Space height={16} />
              <Text xLargeBodyBold>2M+ pages</Text>
              <Space height={16} />
              <Text smallBodyMedium>We scanned more than 2 million pages across 40,000 enterprise domains.</Text>
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard noCard>
              <PhotoVideo />
              <Space height={16} />
              <Text xLargeBodyBold>3B+ elements</Text>
              <Space height={16} />
              <Text smallBodyMedium>
                We tested more than 3 billion page elements (e.g., images and links) against the Web Content
                Accessibility Guidelines (WCAG) 2.1 criteria.
              </Text>
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard noCard>
              <Checklist />
              <Space height={16} />
              <Text xLargeBodyBold>74M errors</Text>
              <Space height={16} />
              <Text smallBodyMedium>
                Our scan revealed 74 million page elements that failed one of the WCAG criteria for accessibility.
              </Text>
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="lg" paddingBottom="lg" constraint="lg" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          EXPERT AUDITS
        </Text>
        <Space height={32} />
        <Columns unorderedList>
          <Column size={8} listItem>
            <IconCard noCard>
              <PersonComputer />
              <Space height={16} />
              <Text xLargeBodyBold>21 sites</Text>
              <Space height={16} />
              <Text smallBodyMedium>
                Our expert testers audited 18 sites in the S&P 500 — plus 3 of the most-trafficked government sites.
              </Text>
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard noCard>
              <MultiSites />
              <Space height={16} />
              <Text xLargeBodyBold>102 pages</Text>
              <Space height={16} />
              <Text smallBodyMedium>
                We tested up to 5 pages per site, focusing on pages that supported key industry goals (e.g., product
                pages for retailers, booking pages for travel sites).
              </Text>
            </IconCard>
          </Column>
          <Column size={8} listItem>
            <IconCard noCard>
              <LockError />
              <Space height={16} />
              <Text xLargeBodyBold>274 barriers</Text>
              <Space height={16} />
              <Text smallBodyMedium>
                In total, our testers found 274 significant accessibility barriers, many of which prevented users from
                accomplishing key tasks on that page.
              </Text>
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <Section backgroundColor="orange200" paddingTop="lg" constraint="lg" innerConstraint="md">
        <Text largeCapsInter color="orange900">
          TOP BARRIERS
        </Text>
        <Space height={32} />
        <Heading2 heading2 className="asH3">
          The top accessibility barriers driving our key insights.
        </Heading2>
        <Space height={32} />
        <Text>
          Our automated scan revealed <BoldText>70 different types of accessibility barriers</BoldText>, based on 25 of
          the 78 WCAG criteria. The number of barriers identified exceeds the number of criteria tested because some
          WCAG criteria address multiple page elements. For example:{' '}
          <TextLink
            to="https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html"
            target="_blank"
            rel="noreferrer"
            text="WCAG Success Criterion 1.1.1: Non-text Content"
            anchorClassName="unstyled-link dai-link"
          />{' '}
          requires that all images have a text alternative. But it also applies to emoticons, map areas, images as
          links, and more.
        </Text>
        <Space height={32} />
        <Text>
          We limited our scan to 25 criteria because some barriers cannot be reliably tested by automation alone. For
          that reason, the true number of issues per page is likely higher. And it’s why we always stress the importance
          of backing automated scans with expert audits by human testers — including members of the disability
          community.
        </Text>
        <Space height={32} />
        <Text>
          Of the 70 barriers that could be detected by automation, the most frequent were related to{' '}
          <BoldText>image accessibility, keyboard accessibility, and descriptive links or labels.</BoldText>
        </Text>
      </Section>
      <Section backgroundColor="orange200" paddingTop="lg" constraint="lg">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../images/dai/stat-sign/circle-sign-69.png"
                alt="Pie chart representing 69%. The pie chart is in the shape of a circular road sign, with the accessibility icon in the center of the circle."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <LabelText>1. MISSING ALT TEXT</LabelText>
            <Space height={24} />
            <Text heading3 className="asH4">
              69% of pages had at least one image with inadequate or missing image alternative text.
            </Text>
            <Space height={24} />
            <Text>
              Vague or missing alt text can make it hard for some people with visual and cognitive impairments to
              understand what an image is supposed to illustrate.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html"
                target="_blank"
                rel="noreferrer"
                text="1.1.1: Non-Text Content"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section backgroundColor="orange200" paddingTop="lg" constraint="lg">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../images/dai/stat-sign/circle-sign-64.png"
                alt="Pie chart representing 64%. The pie chart is in the shape of a circular road sign, with the accessibility icon in the center of the circle."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <LabelText>2. Vague Links</LabelText>
            <Space height={24} />
            <Text heading3 className="asH4">
              64% of pages had at least one link that did not clearly describe where it would take users.
            </Text>
            <Space height={24} />
            <Text>
              Without clear, descriptive links, it can be hard for screen reader users and people with cognitive
              impairments to confidently navigate between pages.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-in-context.html"
                target="_blank"
                rel="noreferrer"
                text="2.4.4: Link Purpose"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Mobility
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section backgroundColor="orange200" paddingTop="lg" constraint="lg">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../images/dai/stat-sign/circle-sign-54.png"
                alt="Pie chart representing 54%. The pie chart is in the shape of a circular road sign, with the accessibility icon in the center of the circle."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <LabelText>3. Keyboard barriers</LabelText>
            <Space height={24} />
            <Text heading3 className="asH4">
              54% of pages had at least one page action that could not be completed with a keyboard alone.
            </Text>
            <Space height={24} />
            <Text>
              Keyboard-only users must be able to interact with every link and dropdown list on a page using keyboard
              commands (e.g., pressing the <BoldText>Tab</BoldText> or <BoldText>Page Down</BoldText> keys).
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/keyboard.html"
                target="_blank"
                rel="noreferrer"
                text="2.1.1: No Keyboard"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Mobility
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section backgroundColor="orange200" paddingTop="lg" constraint="lg">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../images/dai/stat-sign/circle-sign-44.png"
                alt="Pie chart representing 44%. The pie chart is in the shape of a circular road sign, with the accessibility icon in the center of the circle."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <LabelText>4. Missing Page Titles</LabelText>
            <Space height={24} />
            <Text heading3 className="asH4">
              44% of pages lacked a title that described its topic or purpose.
            </Text>
            <Space height={24} />
            <Text>
              Without clear, descriptive page titles, it can be hard for screen reader users to quickly determine if the
              information on a page is relevant to their needs.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/page-titled.html"
                target="_blank"
                rel="noreferrer"
                text="2.4.2: Page Titled"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive, Mobility
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section backgroundColor="orange200" paddingTop="lg" paddingBottom="xl" constraint="lg">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../images/dai/stat-sign/circle-sign-43.png"
                alt="Pie chart representing 43%. The pie chart is in the shape of a circular road sign, with the accessibility icon in the center of the circle."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <LabelText>5. Missing Form Labels</LabelText>
            <Space height={24} />
            <Text heading3 className="asH4">
              43% of pages failed to provide a descriptive label for every form field.
            </Text>
            <Space height={24} />
            <Text>
              Missing or non-descriptive form labels can make it hard for screen reader users to know what information
              to enter into each field.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/headings-and-labels"
                target="_blank"
                rel="noreferrer"
                text="2.4.6: Headings and Labels"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="xl" paddingBottom="xl" constraint="lg" backgroundColor="orange300">
        <Columns>
          <Column size={12}>
            <Heading2 heading2 className="asH3">
              Get expert insight into key accessibility barriers on your site.
            </Heading2>
          </Column>
          <Column size={12} justifyCenter alignItemsEnd>
            <Button
              text="Schedule Expert Audit"
              to="/digital-accessibility-index/overall-results/#expert-audit"
              size="small"
              tag="DAI Page Content"
            />
          </Column>
        </Columns>
      </Section>
      <PageNavigation
        previousPageLink="/digital-accessibility-index/industry-reports/government/"
        previousPageText="Government"
        nextPageLink="/digital-accessibility-index/next-steps/"
        nextPageText="Next Steps"
      />
    </LayoutDAI>
  )
}
