/* eslint-disable indent */
import React from 'react'
import styled from '@emotion/styled'

import Text from '../common/Text'

const StyledText = styled(Text)`
  padding: 12px;
  background-color: ${props => props.theme.color.orange300};
  color: ${props => props.theme.color.orange900} !important;
  text-transform: uppercase;
  border-radius: 8px;
  display: inline;
  width: fit-content;
  line-height: 1.2rem;
`

const LabelText = ({ children }) => <StyledText largeCaps>{children}</StyledText>

export default LabelText
